
import { _3dViewer } from "@evercam/shared/types"
import { useProjectStore } from "@/stores/project"

export default {
  middleware({ redirect, route }) {
    const projectStore = useProjectStore()

    switch (route?.query?.viewer) {
      case _3dViewer.Cesium:
        redirect(`${projectStore.projectRoute}/drone`)
        break
      case _3dViewer.Itwin:
        redirect(`${projectStore.projectRoute}/bim`)
        break
      default:
        redirect(`${projectStore.projectRoute}`)
    }
  },
}
